<!--
 * @Author: 小洋人
 * @Date: 2024-07-10 15:51:38
 * @LastEditors: 小洋人
 * @LastEditTime: 2024-07-10 17:40:28
 * @FilePath: \kangmisi-officialwebsite\src\views\newsCenter.vue
 * @email: 1172457354@qq.com
 * @Description: 
-->
<template>
  <div class="page" id="page">
    <heade :active="5"></heade>
    <div style="height: 64px"></div>
    <div class="page-bg" :style="{ height: bannerHeight + 'px' }">
      <img
        src="../assets/images/newsCenter/bg.png"
        alt=""
        :style="{ height: bannerHeight + 'px' }"
      />
      <div class="page-text">
        <div class="china">资讯中心</div>
        <div class="english">Counseling center</div>
      </div>
    </div>
    <div class="content">
      <div v-for="(item, index) in list" :key="index" class="list">
        <div>
          <img
            :src="require(`@/assets/images/newsCenter/img${index + 1}.png`)"
            alt=""
          />
        </div>
        <div class="item">
          <div class="title">{{ item.title }}</div>
          <div class="desc">{{ item.desc }}</div>
          <div class="detail" @click="goDetails(item.id)">查看详情</div>
        </div>
      </div>
    </div>
    <!-- 首页 -->
    <template>
      <bottom></bottom>
    </template>
  </div>
</template>

<script>
import bottom from "./bottom.vue";
import heade from "./header.vue";

export default {
  components: { heade, bottom },
  data() {
    return {
      bannerHeight: 0,
      list: [
        {
          id: 1,
          title: "车辆监控与风险预警 让租赁 信贷 抵押车 汽车金融风控更简单",
          desc: "汽车因其便利性被广泛使用，汽车贷款行业随之蓬勃发展。汽车贷款企业需确保贷款车辆安全和客户信用风险得到有效控制，这就需要借助定位技术。车贷风控管理主要分为两方面：一是贷款前的车辆风险评估，二是贷款后对借款人还款能力和行为的监控。贷款前评估相对容易，而了解借款人行为则需要通过GPS定位系统来辅助，以降低车辆丢失风险。",
        },
        {
          id: 2,
          title: "提升效率，保障安全 康米斯打造简洁易用的车辆远程调度体系",
          desc: "如何高效管理车队和司机，降低成本并提高效率，是物流行业长期关注的问题。以下是几个提高车队管理效率的方法",
        },
        {
          id: 3,
          title:
            "从传统工地到智慧工地 车辆/人员定位终端助力构建安全高效的管理。",
          desc: "在建筑行业中，施工安全是至关重要的基石，它不仅保障了施工企业能够顺利进行工作，而且直接影响到生产效率的提升。随着信息技术、移动技术、智能穿戴设备和工具的快速发展，智慧工地的概念应运而生，为施工安全管理带来了革命性的变革。同时，中国建筑业协会发布的《智慧工地技术标准》公告，为智慧工地建设提供了规范化、智能化和标准化的重要指导。",
        },
        {
          id: 4,
          title: "智慧冷链解决方案 实时温湿度监测 加强货物监控与安全",
          desc: "随着人们对生鲜冷链需求的日益增长，冷链物流行业正迎来以物联网技术为主导的发展新阶段。我国冷链物流业正处于快速发展期，向网络化、标准化、规模化、集团化方向迅速迈进，展现出巨大的市场潜力。然而，现有的冷链车监控系统往往无法提供全面的监控，冷链运输的温度控制、操作规范等方面缺少统一标准，各环节信息资源难以有效整合，一旦出现问题，难以进行有效追溯。",
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.imgLoag();
    window.addEventListener(
      "resize",
      () => {
        this.imgLoag();
      },
      false
    );
  },
  methods: {
    imgLoag() {
      this.$nextTick(() => {
        const page = document.getElementById("page");
        const pageWidth = page.offsetWidth;
        this.bannerHeight = (pageWidth * 560) / 1920;
      });
    },
    goDetails(id) {
      this.$router.push("/newsCenterdetail?id=" + id);
    },
  },
};
</script>
<style lang="less" scoped>
.page-bg {
  width: 100vw;
  position: relative;
  img {
    width: 100vw;
  }
}
.page-text {
  position: absolute;
  top: 42%;
  left: 19.1%;
  color: #fefefe;
  // text-shadow: 0px 3px 2px rgba(0, 0, 0, 0.16);
  .china {
    font-weight: 300;
    font-size: 40px;
  }
  .english {
    font-weight: 300;
    font-size: 24px;
    margin-top: 3px;
  }
}
.content {
  min-width: 1200px;
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 250px;
  margin-top: 20px;

  background-color: #fff;

  img {
    width: 240px;
    height: 240px;
  }
  .list:last-child {
    border-bottom: none;
  }
  .list {
    padding: 48px 0;
    border-bottom: 1px solid #dbdbdb;
    display: flex;
    align-items: center;
    .item {
      width: 960px;
      min-width: 960px;
      margin-left: 48px;
    }
    .title {
      font-size: 16px;
      color: #2c2c2c;
    }
    .desc {
      font-weight: 300;
      font-size: 14px;
      color: #2c2c2c;
      margin: 16px 0;
    }
    .detail {
      width: 80px;
      height: 37px;
      background: #1c64c2;
      border-radius: 4px 4px 4px 4px;
      font-size: 16px;
      color: #ffffff;
      line-height: 37px;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>
